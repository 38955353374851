<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>{{ SlpName }} - Target Skus</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon to="/targets">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="skus" :search="search">
              <!-- outlet template -->
              <template v-slot:item.oitm="props">
                <v-btn
                  color="flatButton"
                  text
                  :to="`/item-master-data/${props.item.oitm.id}`"
                  >{{ props.item.oitm.ItemName }}</v-btn
                >
              </template>
              <!-- end -->
              <template v-slot:item.TargetValue>{{ TargetValue }}</template>

              <template v-slot:item.Achieved>0</template>
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar refs="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    search: null,
    targetData: [],
    SlpName: null,
    TargetValue: null,
    skus: [],
    editMode: false,
    headers: [
      { text: "Sku", value: "oitm" },
      { text: "Target Value", value: "TargetValue" },
      { text: "Achieved", value: "Achieved" },
    ],
  }),
  watch: {
    "$route.params.id": {
      handler: "getTarget",
      immediate: true,
    },
  },
  methods: {
    getTarget(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/get_targets_skus/${val}`)
        .then((res) => {
          console.log(res, "single target");
          self.targetData = res;
          self.SlpName = res.employees.SlpName;
          self.TargetValue = res.Tvalue;
          self.skus = res.document_lines;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {},
};
</script>